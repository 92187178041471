import { BaseIp } from "./ApiURL";


//Ebooks
export const apiBooksPath = BaseIp + "pdf/api/Books/";

//Q Paper
export const apiQpaperPath = BaseIp + "pdf/api/qpaper/";


//Syllabus

export const apiSyllabusPath = BaseIp + "pdf/api/syllabus/";

//FAQ
export const apiFaqPath = BaseIp + "pdf/api/faq/";