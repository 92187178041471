import React, { useState, useEffect } from 'react';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import TablePagination from '@mui/material/TablePagination';
import { BsEyeFill, BsTrashFill, BsFileEarmarkPdfFill } from 'react-icons/bs';
import Badge from 'react-bootstrap/Badge';
//Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';


//Loader
import Loader from '../CommonComponents/Loader/Loader';

//Delete Modal
import DeleteModal from '../CommonComponents/DeleteModalComponent/DeleteModal';

//APIURL
import { getLinksAPI, saveLinksAPI, deleteLinkAPI } from "../../Services/LinksAPIURL";


const Links = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(titleLabelAction({ titleLabel: "LINKS" }))
    }, [])

    const authUser = useSelector((state) => state.LoginReducer.list[0]);
    const [loaderOption, setLoaderOption] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //Data
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([])
    //console.log("data :" + JSON.stringify(data))

    //Form
    const [id, setId] = useState("");
    const [linkName, setLinkName] = useState("");
    const [siteLink, setSiteLink] = useState("");

    //Table

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const TableHeading = (authUser.member_type === 1) ? [
        { label: 'ID', key: 'id' },
        { label: `Name`, key: 'name' },
        { label: 'Link', key: 'link_name', isAction: true },
        { label: 'Delete', key: 'del', value: 'delete', isAction: true }
    ]
        :
        [
            { label: 'ID', key: 'id' },
            { label: `Name`, key: 'name' },
            { label: 'Link', key: 'link_name', isAction: true }
        ]

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);

    const saveFun = async () => {
        setLoaderOption(true);
        const saveObj = {
            "name": linkName,
            "link_name": siteLink,
            "institute_id": authUser.institute_id
        }

        await axios.post(saveLinksAPI, saveObj)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error occoured, Operation failed.")
                }
                else if (res.data === "SAVED") {
                    setLoaderOption(false);
                    toast.success("Link saved sucessfully.")
                    getData();
                    clearFun()
                }
                else {
                    setLoaderOption(false);
                    toast.error("Something went wrong, Try again")
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network Error. try again sometime");
                console.log("Error :" + err)
            })
    }


    const getData = async () => {
        setLoaderOption(true);
        await axios.post(getLinksAPI + authUser.institute_id)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error Occured.");
                }
                else {

                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network error, Try after sometime.")
                console.log("Error : " + err)
            })
    }

    const deleteFun = async () => {
        setShowDeleteModal(false);
        const deleteObj = {
            "id": id,
            "institute_id": authUser.institute_id
        }

        await axios.post(deleteLinkAPI, deleteObj)
            .then((res) => {
                if (res.data === "ERROR") {
                    toast.error("Error occoured, try again")
                }
                else if ("DELETED") {
                    getData();
                    toast.success("Link deleted sucessfully.");
                }
                else {
                    toast.error("Something went wrong, try after sometime")
                }
            })
            .catch((err) => {
                console.log("Error :" + err);
                toast.error("Network Error, Try after sometime.")
            })
    }

    const clearFun = () => {
        setLinkName("");
        setSiteLink("");
    }

    useEffect(() => {
        if (authUser.institute_id) {
            getData();
        }
    }, [authUser])


    return (
        <>
            {(loaderOption) ? <Loader /> : null}

            {
                (authUser.member_type) ?

                    (authUser.member_type === 1) ?

                        <div className="row">
                            <form onSubmit={saveFun} >

                                <div className='row'>


                                    <label className="control-label col-sm-1" htmlFor="linkName" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>
                                        Name:
                                    </label>
                                    <div className="col-sm-4">
                                        <TextField id="linkName" label="Name of Link" variant="outlined" size='small' margin='dense' className='form-control'
                                            value={linkName}
                                            onChange={(e) => setLinkName(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <label className="control-label col-sm-1" htmlFor="Links" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>
                                        Link:
                                    </label>
                                    <div className="col-sm-5">
                                        <TextField id="Links" label="Site Link" variant="outlined" size='small' margin='dense' className='form-control'
                                            value={siteLink}
                                            onChange={(e) => setSiteLink(e.target.value)}
                                            required
                                        />
                                    </div>


                                </div>

                                <div className="row mt-2">
                                    <div className="col-sm-4 offset-sm-1">
                                        <input className='btn btn-primary btn-sm' type="submit" value="SAVE" />

                                        <input className='btn btn-danger btn-sm mx-2' type="reset" value="CANCEL" />
                                    </div>
                                </div>

                            </form>
                            <hr />
                        </div>

                        : null

                    : null
            }



            <div>

                <div className='row'>
                    <div >
                        <input
                            className='form-control form-control-sm'
                            type="text"
                            style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(event) => { setSearchTerm(event.target.value); }}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="table-responsive mt-2">
                        <Table striped bordered hover size="sm" >
                            <thead className="table-Default">
                                <tr style={{ backgroundColor: "#F5E3E6", backgroundImage: "linear-gradient(200deg, #F5E3E6 10%, #D9E4F5 100%)" }}>
                                    {TableHeading.map((item, ind) => {
                                        return (
                                            <th
                                                style={{ fontSize: "12px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}
                                                onClick={() => onSort(item.key, item.isAction)}
                                                key={ind}
                                                scope="col"
                                            >
                                                {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderBottom: "2px solid #D8EDF7", width: "100%", wordWrap: "break-word" }}>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.id}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500", wordWrap: "break-word" }}>
                                                    <div style={{ width: "300px" }}>
                                                        {item.name}
                                                    </div>
                                                </td>


                                                <td style={{ fontSize: "14px", fontWeight: "500", wordWrap: "break-word" }}>
                                                    {
                                                        (authUser.member_type === 1) ?

                                                            <div style={{ width: "300px" }}>
                                                                <a href={item.link_name} target="_blank">{item.link_name}</a>
                                                            </div>

                                                            :

                                                            <a href={item.link_name} target="_blank">
                                                                <Badge bg="primary">VIEW</Badge>
                                                            </a>


                                                    }

                                                </td>

                                                {
                                                    (authUser.member_type) ?

                                                        (authUser.member_type === 1) ?

                                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                                <button className='btn btn-danger btn-sm p-0 px-1'
                                                                    onClick={() => { setId(item.id); setShowDeleteModal(true) }}
                                                                >
                                                                    <BsTrashFill style={{ fontSize: "14px", margin: "0px" }} />
                                                                </button>
                                                            </td>

                                                            : null

                                                        : null
                                                }

                                            </tr>
                                        )
                                    })}
                            </tbody>
                            <tfoot >
                                <tr style={{ padding: "4px" }}>
                                    <TablePagination
                                        style={{ padding: 0, borderBottom: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        labelRowsPerPage=" Rows : "
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        //classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                                    />
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </div>
            </div>
            <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} deleteFun={deleteFun} />
            <ToastContainer theme='dark' position='top-right' />
        </>
    )
}

export default Links