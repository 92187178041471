import React, { useState, useEffect } from 'react';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
//Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';


//Loader
import Loader from '../CommonComponents/Loader/Loader';


//APIURL
import { changePasswordAPI } from "../../Services/PasswordAPIURL";

const ChangePassword = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(titleLabelAction({ titleLabel: "CHANGE PASSWORD" }))
    }, [])

    const authUser = useSelector((state) => state.LoginReducer.list[0]);
    const [loaderOption, setLoaderOption] = useState(false);


    //Form Fields

    const [password, setPassword] = useState("");
    const [conPassword, setConPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [errMsg, setErrMsg] = useState("");

    const clearFun = () => {
        setPassword("");
        setConPassword("");
        setOldPassword("");
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (password !== conPassword) {
            setErrMsg("Password & Confirm Password dosent match")
            toast.warn("Password & Confirm Password dosent match")
        }
        else {
            setErrMsg("")

            const passObj = {
                "oldpassword": oldPassword,
                "password": password,
                "member_type": authUser.member_type,
                "id": authUser.id
            }
            await axios.post(changePasswordAPI, passObj)
                .then((res) => {
                    if (res.data === "ERROR") {
                        toast.error("Error occured, try again.")
                    }
                    else if (res.data === "UPDATED") {
                        clearFun();
                        toast.success("Password updated sucessfully.")
                    }
                    else if (res.data === "NOTMATCH1") {
                        toast.error("Old Password dosent match. USER")
                    }
                    else if (res.data === "NOTMATCH2") {
                        toast.error("Old Password dosent match. ADMIN")
                    }

                })
                .catch((err) => {
                    console.log("Error :" + err);
                    toast.error("Network Error, Try again later")
                })
        }
    }

    return (
        <>
            {(loaderOption) ? <Loader /> : null}

            <div className="row p-2">
                <div className="col-sm-4">
                    <Form onSubmit={onSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicOldPassword">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control type="password" placeholder="Old Password" required size={"sm"}
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" required size={"sm"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicConPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm Password" required size={"sm"}
                                value={conPassword}
                                onChange={(e) => setConPassword(e.target.value)}
                            />
                        </Form.Group>

                        <div className="row mb-3">
                            <em style={{ color: "red", fontWeight: "600", fontSize: "12px" }}>{errMsg}</em>
                        </div>

                        <Button variant="primary" type="submit" className='btn-sm'>
                            Submit
                        </Button>

                        <Button variant="danger" type="reset" className='btn-sm mx-2'
                            onClick={() => clearFun()}
                        >
                            Cancle
                        </Button>
                    </Form>
                </div>
            </div>

            <ToastContainer theme='dark' position='top-right' />
        </>
    )
}

export default ChangePassword