import React from 'react'
import { BsGripVertical } from 'react-icons/bs';
import { useSelector } from 'react-redux';

const TitleLabel = () => {

    const titleLabel = useSelector((state) => state.TitleLabelReducer.list[0])
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <BsGripVertical style={{ fontSize: "24px", color: "#5B2C6F" }} />
            <strong style={{ fontSize: "24px", fontWeight: "600", color: "#5B2C6F" }}>{titleLabel.titleLabel}</strong>
        </div>
    )
}

export default TitleLabel