import React, { useEffect } from 'react'
import './navBar.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaUserAlt, FaQuinscape, FaWhmcs, FaHandPointUp, FaLink } from 'react-icons/fa'
import { AiFillHome } from 'react-icons/ai'
import { SiSemanticweb } from 'react-icons/si';
import { HiBanknotes } from 'react-icons/hi2'
import { BsFillFileSpreadsheetFill } from 'react-icons/bs'
import { MdNoteAlt } from 'react-icons/md'
import logo from '../../Assets/images/logo.png';

//Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { userLogout, userLogin } from '../../ReduxStore/Action/LoginAction';
import { useNavigate } from 'react-router-dom';

const NavbarMain = ({ setIsUser }) => {
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.LoginReducer.list[0])
    const dispatch = useDispatch()
    const logoutFun = () => {
        sessionStorage.removeItem("digital-library")
        dispatch(userLogout())
        setIsUser(false)
        navigate("/")

    }


    useEffect(() => {
        const sessionData = sessionStorage.getItem("digital-library")
        if (sessionData) {
            const parseData = JSON.parse(sessionData);
            dispatch(userLogin(parseData));
        }
    }, [])


    return (
        <>
            <div className='bg-light'>
                <div className="container pt-2">
                    <div className="row">
                        <div className="col-2 col-sm-1">
                            <img src={logo} alt="" className='headLogo' />
                        </div>

                        <div className="col-10 col-sm-10">
                            <div style={{ textAlign: "center" }}>
                                <p className='titleSanstha'>Anekant Education Society's, Baramati</p>
                                <h3 className='titleHeader'>JAYSINGPUR COLLEGE, JAYSINGPUR</h3>
                            </div>
                        </div>

                        <div className="col-sm-1 pt-3">
                            <Dropdown style={{ textAlign: "right" }}>
                                <Dropdown.Toggle variant="primary" size='sm' style={{ fontSize: "12px", backgroundColor: "#f26522", border: "0px solid" }}>
                                    <FaUserAlt size={"12px"} style={{ marginTop: "-4px" }} /> MANAGE
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ fontSize: "12px" }}>
                                    <div className='text-center p-2' style={{ backgroundColor: "#F9EBEA", fontWeight: "600" }}>
                                        {authUser.member_name}
                                    </div>
                                    <Dropdown.Item href="#/n011">
                                        CHANGE PASSWORD
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={logoutFun}>
                                        LOGOUT
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ boxShadow: "0px 15px 10px -15px #D5D8DC", marginTop:"10px" }}>
                <Navbar collapseOnSelect expand="lg" className='p-0 blueBg' bg="light" variant="light" style={{ fontSize: "13px" }}>
                    <Container>
                        <Navbar.Brand href="#home"></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">

                            <Nav className="me-auto">
                                <Nav.Link className='navLinkItem' href="#/"><span className="linkIcon"><AiFillHome style={{ marginTop: "-6px" }} aria-hidden="true" /> </span> HOME</Nav.Link>
                                <Nav.Link className='navLinkItem' href="#/n001"><span className="linkIcon"><SiSemanticweb style={{ marginTop: "-6px" }} /> </span> WEB OPAC</Nav.Link>

                                <Nav.Link className='navLinkItem' href="#/n002"><span className="linkIcon"><FaHandPointUp style={{ marginTop: "-6px" }} /> </span> N-LIST</Nav.Link>
                                <Nav.Link className='navLinkItem' href="#/n003"><span className="linkIcon"><HiBanknotes style={{ marginTop: "-6px" }} /> </span> E-BOOKS</Nav.Link>
                                <Nav.Link className='navLinkItem' href="#/n004"><span className="linkIcon"><BsFillFileSpreadsheetFill style={{ marginTop: "-6px" }} /> </span> Q-PAPERS</Nav.Link>
                                <Nav.Link className='navLinkItem' href="#/n005"><span className="linkIcon"><MdNoteAlt style={{ marginTop: "-6px" }} /> </span> SYLLABUS</Nav.Link>
                                <Nav.Link className='navLinkItem' href="#/n006"><span className="linkIcon"><FaQuinscape style={{ marginTop: "-6px" }} /> </span> FAQ</Nav.Link>
                                <Nav.Link className='navLinkItem' href="#/n007"><span className="linkIcon"><FaLink style={{ marginTop: "-6px" }} /> </span> LINKS</Nav.Link>

                                {
                                    (authUser.member_type === 1) ?
                                        <NavDropdown className='navLinkItem' title={<span className="linkIcon"><FaWhmcs style={{ marginTop: "-6px" }}/> UTILITIES</span>} id="collasible-nav-dropdown">
                                            <NavDropdown.Item href="#/n080" style={{ fontSize: "14px" }}>USER CREATION</NavDropdown.Item>
                                            <NavDropdown.Item href="#/n081" style={{ fontSize: "14px" }}>
                                                MEMBER REPORT
                                            </NavDropdown.Item>
                                            <NavDropdown.Item href="#/n082" style={{ fontSize: "14px" }}>CATEGORY MASTER</NavDropdown.Item>
                                            <NavDropdown.Item href="#/n083" style={{ fontSize: "14px" }}>VISITOR COUNT</NavDropdown.Item>

                                        </NavDropdown>
                                        : null
                                }


                            </Nav>
                            {/* <Nav>
                            <Nav.Link href="#deets">More deets</Nav.Link>
                            <Nav.Link eventKey={2} href="#memes">
                                Dank memes
                            </Nav.Link>
                        </Nav> */}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}

export default NavbarMain;