import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import TitleLabelReducer from "./TitleLabelReducer";


const rootReducer = combineReducers({
    LoginReducer,
    TitleLabelReducer

})

export default rootReducer;