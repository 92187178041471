import React, { useState, useEffect } from 'react';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import { ToastContainer, toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import TablePagination from '@mui/material/TablePagination';
import { BsEyeFill, BsTrashFill, BsFileEarmarkPdfFill } from 'react-icons/bs';
import moment from 'moment/moment';
//Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';

import { CSVLink } from "react-csv";
//Loader
import Loader from '../CommonComponents/Loader/Loader';

//APIURL 
import { visitorCountGetDataAPI } from '../../Services/VisitorCountAPIURL';
import { SiMicrosoftexcel } from 'react-icons/si'

const VisitorCount = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const authUser = useSelector((state) => state.LoginReducer.list[0]);
    const [loaderOption, setLoaderOption] = useState(false);

    useEffect(() => {
        dispatch(titleLabelAction({ titleLabel: "VISITOR COUNT" }))
    }, [])

    useEffect(() => {
        if (authUser.member_type) {
            if (authUser.member_type !== 1) {
                navigate("/")
            }
        }
    }, [authUser])



    //Data
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([])

    //Table

    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const TableHeading = [
        { label: 'User Id', key: 'UserId' },
        { label: `Member Name`, key: 'MemberName' },
        { label: 'Class Name', key: 'ClassName' },
        { label: 'Designation', key: 'Designation' },
        { label: 'Login Type', key: 'LoginType' },
        { label: 'Login Date', key: 'LoginDate' }
    ]

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.MemberName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.UserId.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);


    const getData = async () => {
        setLoaderOption(true);
        await axios.post(visitorCountGetDataAPI + authUser.institute_id)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error Occured.");
                }
                else {

                    let tempData = res.data.map((e, i) => {
                        return e[i] = { ...e, "LoginType": (e.LoginType === 1) ? "Web App" : "Mobile App" }
                    })

                    setData(tempData);
                    setFilteredData(tempData);
                    setLoaderOption(false);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network error, Try after sometime.")
                console.log("Error : " + err)
            })
    }

    useEffect(() => {
        if (authUser.institute_id) {
            getData();
        }
    }, [authUser])

    return (
        <>
            {(loaderOption) ? <Loader /> : null}
            <CSVLink data={data} className="btn btn-sm btn-success">
                <SiMicrosoftexcel style={{ fontSize: "18px" }} /> Export Data
            </CSVLink>
            <div>

                <div className='row'>
                    <div >
                        <input
                            className='form-control form-control-sm'
                            type="text"
                            style={{ width: "250px", display: "block", float: "right", marginBottom: "0px", border: "1px solid #C2C1C1", fontSize: "12px" }}
                            placeholder="Search Here"
                            value={searchTerm}
                            onChange={(event) => { setSearchTerm(event.target.value); }}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="table-responsive mt-2">
                        <Table striped bordered hover size="sm" >
                            <thead className="table-Default">
                                <tr style={{ backgroundColor: "#F5E3E6", backgroundImage: "linear-gradient(200deg, #F5E3E6 10%, #D9E4F5 100%)" }}>
                                    {TableHeading.map((item, ind) => {
                                        return (
                                            <th
                                                style={{ fontSize: "12px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}
                                                onClick={() => onSort(item.key, item.isAction)}
                                                key={ind}
                                                scope="col"
                                            >
                                                {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody style={{ borderBottom: "2px solid #D8EDF7", width: "100%", wordWrap: "break-word" }}>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.UserId}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}> {item.MemberName}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}> {item.ClassName}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}> {item.Designation}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}> {item.LoginType}</td>
                                                <td style={{ fontSize: "14px", fontWeight: "500" }}> {item.LoginDate}</td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                            <tfoot >
                                <tr style={{ padding: "4px" }}>
                                    <TablePagination
                                        style={{ padding: 0, borderBottom: "0px" }}
                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                        labelRowsPerPage=" Rows : "
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        onPageChange={onPageChange}
                                        //classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                        backIconButtonProps={{ "aria-label": "Previous Page" }}
                                        nextIconButtonProps={{ "aria-label": "Next Page" }}
                                    />
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VisitorCount