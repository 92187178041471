import { ProIp } from "./ApiURL";


// Post Mapping :id  institute id

export const getLinksAPI = ProIp + "Links/getLinks/";


//Post Mapping

export const saveLinksAPI = ProIp + "Links/saveLinks";


//post Mapping
export const deleteLinkAPI = ProIp + "Links/deleteLinks";