import React from 'react'

//Navbar 
import NavbarMain from '../Navbar/NavbarMain';

//Footer
import Footer from '../Footer/Footer'
//MasterContent
import MasterContent from '../MasterContent/MasterContent';


const MainComponent = ({ setIsUser }) => {

    return (
        <div style={{ display: "block", backgroundColor: "#fff", minHeight: "100vh" }}>
            <NavbarMain setIsUser={setIsUser} />
            <MasterContent />
            <Footer />
        </div>
    )
}

export default MainComponent