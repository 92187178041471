import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { BsEyeFill, BsTrashFill, BsFileEarmarkPdfFill } from 'react-icons/bs';
//Loader
import Loader from '../CommonComponents/Loader/Loader';
//Delete Modal
import DeleteModal from '../CommonComponents/DeleteModalComponent/DeleteModal';
//Redux
import { useDispatch, useSelector } from 'react-redux';

import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';

//Apiurl
import { saveSyllabusAPI, getSyllabusAPI, apiSyllabusDeleteAPI } from '../../Services/SyllabusAPIURL';
import { getCategoryMasterAPI } from '../../Services/CategoryAPIURL';
import { apiSyllabusPath } from '../../Services/Path'

const Syllabus = () => {

  const dispatch = useDispatch()
  const authUser = useSelector((state) => state.LoginReducer.list[0])

  const [loaderOption, setLoaderOption] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  //Category Data list

  const [categoryData, setCategoryData] = useState([])
  const [data, setData] = useState([])



  //Form Variable
  const [category, setCategory] = useState(null);
  const [filterCategory, setFilterCategory] = useState(null);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null)


  const getData = async () => {
    setLoaderOption(true);
    await axios.post(getCategoryMasterAPI + authUser.institute_id)
      .then((res) => {
        if (res.data !== null) {
          setCategoryData(res.data);
        }
      })
      .catch((err) => {
        toast.error("Category Network Error");
        console.log("Error :" + err)
      })

    await axios.post(getSyllabusAPI + authUser.institute_id)
      .then((res) => {
        if (res.data === "ERROR") {
          toast.error("Ebook Data Fetching Error");
        }
        else {
          setData(res.data);          
          setLoaderOption(false);
        }
      })
      .catch((err) => {
        toast.error("Ebook Network Error");
        console.log("Error : " + err)
      })
  }

  useEffect(() => {
    if (authUser.institute_id) {
      getData();
    }
  }, [authUser])


  useEffect(() => {
    dispatch(titleLabelAction({ titleLabel: "SYLLABUS" }))
  }, [])

  const clearFun = () => {
    setCategory(null)
    setFileName("")
    setFile(null)
    document.getElementById("uploadFile").value = "";
  }

  const saveFun = async (e) => {
    e.preventDefault();
    setLoaderOption(true)
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    formData.append("categoryId", category.id);
    formData.append("branchId", authUser.institute_id);

    await axios.post(saveSyllabusAPI, formData)
      .then((res) => {
        if (res.data === "SAVED") {
          setLoaderOption(false);
          toast.success("E-Book Saved Sucessfully.");
          clearFun();
          getData();
        }
        else {
          setLoaderOption(false);
          toast.error("Error Occured, Operation Failed.")
        }
      })
      .catch((err) => {
        setLoaderOption(false);
        console.log("Error : " + err)
      })

  }

  const viewPdf = async (fname) => {
    await axios.post(apiSyllabusPath + fname)
      .then((res) => {
        if (res.data !== null) {
          //console.log(res);
          window.open(decodeURI(res.data), null)
        }
      })
      .catch((err) => {
        console.log("Error : " + err)
      })
  }

  const [delId, setDelId] = useState(null);
  const [delFile, setDelFile] = useState(null);


  const deleteFun = async () => {
    setShowDeleteModal(false);
    //console.log("delete")
    await axios.post(`${apiSyllabusDeleteAPI}${delFile}/${delId}`)
      .then((res) => {
        if (res.data === "ERROR") {
          toast.error("Error Occoured, Operation Failed")
        }
        else if (res.data === "DELETED") {
          toast.success("Sucessfully Deleted.")
          getData();
        }
      })
      .catch((err) => {
        toast.error("Server/Network Error")
        console.log(err)
      })
  }

  return (
    <>
      {(loaderOption) ? <Loader /> : null}

      {
        (authUser.member_type)
          ?
          (authUser.member_type === 1) ?
            <div>
              <form onSubmit={saveFun} action="" encType="multipart/form-data">

                <div className='row'>

                  <label className="control-label col-sm-2" htmlFor="categoryM" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>Category Master :</label>
                  <div className="col-sm-4">
                    <Autocomplete
                      id="categoryM"
                      options={categoryData}
                      value={category}
                      onChange={(event, newValue) => {
                        setCategory(newValue)
                      }}
                      getOptionLabel={(option) => option.category_name}
                      renderInput={(params) => <TextField {...params} label="Select Category" size='small' margin='dense' required />}

                    />
                  </div>

                  <label className="control-label col-sm-1" htmlFor="fileN" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>File Name:</label>
                  <div className="col-sm-4">
                    <TextField id="fileN" label="File Name" variant="outlined" size='small' margin='dense' className='form-control'
                      value={fileName}
                      onChange={(e) => setFileName(e.target.value)}
                      required
                    />
                  </div>

                  <label className="control-label col-sm-2" htmlFor="uploadFile" style={{ fontWeight: "600", fontSize: "14px", marginTop: "16px" }}>Upload File:</label>
                  <div className="col-sm-4">
                    {/* <TextField id="uploadFile" label="File Name" variant="outlined" size='small' margin='dense' className='form-control' /> */}
                    <input type="file" name="file" id="uploadFile" className='form-control my-2' accept="application/pdf" required
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>


                </div>

                <div className="row mt-2">
                  <div className="col-sm-4 offset-sm-2">
                    <input className='btn btn-primary btn-sm' type="submit" value="SAVE" />

                    <input className='btn btn-danger btn-sm mx-2' type="reset" value="CANCEL" onClick={clearFun} />
                  </div>
                </div>

              </form>
              <hr />
            </div>
            : null
          : null

      }



      

      <div className="row">

        {
          (categoryData.length > 0 && authUser.member_type) ?
            categoryData.map((cItem, ind) => {
              let tableData = data.filter((e) => e.category_id === cItem.id);
              if (tableData.length > 0) {
                return (
                  <div className="col-sm-4 col-md-6 col-lg-4 p-2" key={ind}>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>{cItem.category_name}</Accordion.Header>
                        <Accordion.Body style={{ height: "250px", overflowY: "scroll" }}>
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th>Book Name</th>
                                {
                                  (authUser.member_type === 1) ? <th style={{ width: "35px" }}>Delete</th> : null
                                }

                              </tr>
                            </thead>
                            <tbody>
                              {
                                tableData.map((dItem, dInd) => {
                                  return (
                                    <tr key={dInd}>
                                      <td>
                                        <span onClick={() => viewPdf(dItem.filename)} style={{ cursor: "pointer" }}>
                                          <BsFileEarmarkPdfFill style={{color:"#B0052E"}}/> {dItem.name}
                                        </span>
                                      </td>
                                      {/* <td>
                                        <button className='btn btn-primary btn-sm p-0 px-1'

                                          onClick={() => viewPdf(dItem.filename)}>
                                          <BsEyeFill style={{ fontSize: "16px", margin: "0px" }} />
                                        </button>
                                      </td> */}

                                      {
                                        (authUser.member_type === 1) ?
                                          <td>
                                            <button className='btn btn-danger btn-sm p-0 px-1'
                                              onClick={() => {
                                                setDelId(dItem.id);
                                                setDelFile(dItem.filename);
                                                setShowDeleteModal(true)
                                              }}
                                            >
                                              <BsTrashFill style={{ fontSize: "14px", margin: "0px" }} />
                                            </button>
                                          </td>
                                          : null
                                      }

                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                )
              }
            })
            :
            null
        }

      </div>
      <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} deleteFun={deleteFun} />
      <ToastContainer theme='dark' position='top-right' />
    </>
  )
}

export default Syllabus