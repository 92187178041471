import { ProIp } from "./ApiURL";

//post mapping :id institute_id
export const getCategoryMasterAPI = ProIp + "CategoryMaster/getCategoryMaster/";

//postMapping body=branch_name, category_name

export const saveCategoryMasterAPI = ProIp + "CategoryMaster/saveCategoryMaster";


//updateCategoryMaster postMapping body= id, category_name

export const updateCategoryMasterAPI = ProIp + "CategoryMaster/updateCategoryMaster";

//post mapping deleteCategoryMaster body=id

export const deleteCategoryMasterAPI = ProIp + "CategoryMaster/deleteCategoryMaster";