import { BaseIp, ProIp } from "./ApiURL";

//http://192.168.1.101:8088/digital-library/EBook/saveEBook
//[req.body.fileName, req.file.filename, req.body.bid, req.body.catId

export const saveqpaperAPI = ProIp + "qpaper/saveQPaper";


//postMapping :id === institute_id
export const getQPaperAPI = ProIp + "qpaper/getQPaper/";


//Ebooks Delete :fname :id  Postmapping
//http://192.168.1.101:8088/pdf/delete/api/Books/TechnoArav Half Page Adv 2-1668414319131p52u67loog.pdf/340
export const apiQPaperDeleteAPI = BaseIp + "pdf/delete/api/qpaper/";