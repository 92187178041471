import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

const DeleteModal = (props) => {

    return (
        <>
            <Modal
                show={props.showDeleteModal}
                onHide={() => { props.setShowDeleteModal(false) }}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton className='modalHead'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Are you sure, You want to delete ?
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Button type='button' onClick={() => { props.deleteFun() }} size='sm' variant="danger">DELETE</Button>
                    <Button onClick={() => { props.setShowDeleteModal(false) }} size='sm' style={{ marginLeft: "8px" }} variant='light'>CLOSE</Button>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default DeleteModal;

// export const DeleteModal1 = React.memo(DeleteModal)