import React, { useState, useEffect } from 'react'
import Login from './Components/LoginComponents/Login'
import MainComponent from './Components/MainComponent/MainComponent'

//Redux
import { useDispatch } from 'react-redux';
import { userLogin } from './ReduxStore/Action/LoginAction';
import {  Route, Routes, useLocation,useNavigate } from 'react-router-dom';
import CancleMember from './Components/LoginComponents/CancleSubscription';
import PrivacyPolicy from './Components/LoginComponents/PrivacyPolicy';
import { Switch } from '@mui/material';


const App = () => {

 

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isUser, setIsUser] = useState(false);
  const [canclePath, setcanclePath] = useState(false);

  const checkLogin = () => {
    const logData = sessionStorage.getItem("digital-library")
    //console.log(logData)


    if (logData) {
      const convLogData = JSON.parse(logData)
      // console.log(convLogData.loginId + convLogData.password)
      const dispatchLogData = {
        loginId: convLogData.loginId,
        password: convLogData.password
      }
      dispatch(userLogin(dispatchLogData))
      setIsUser(true)
    }
    else {
      setIsUser(false)
    }
  }

  // useEffect(() => {
  //   checkLogin();
  //   // console.log('pathname', location.pathname);
  //   if(location.pathname=="/cancle"){
  //     console.log("cancle patg");
  //     setcanclePath(true)

  //   }
  //   else{
  //     console.log("dsfsdf");
  //   }
  // }, [])

  // const handleCancelPath = () => {
  //   if (window.location.pathname === "/cancel") {
  //     navigate("/cancel-membership");
  //   }
  // };

  // useEffect(() => {
  //   handleCancelPath();
  // }, [window.location.pathname]);


  return (
    <>    
   <Routes>

      <Route path="/cancel-member-page" element={<CancleMember />} />
      
      <Route path="/privacy" element={<PrivacyPolicy />} />
    
    </Routes>


      {isUser ? (
        <MainComponent setIsUser={setIsUser} />
      ) : (
        <Login setIsUser={setIsUser} /> 
      )}
      
      {/* {canclePath ? (
  <div style={{ display: "block", position: "fixed", top: 0, backgroundColor: "#fff", width: "100vw", minHeight: "100vh" }}>
    <h1><CancleMember></CancleMember></h1>
  </div>
) : null} */}
     
    </>
  );
  
}

export default App