import React, { useState, useEffect } from 'react'
import './home.css'
import axios from "axios";
import { BsFillFileSpreadsheetFill } from 'react-icons/bs'
import { SiSemanticweb } from 'react-icons/si';
import { MdPictureAsPdf } from 'react-icons/md';
import { FaLink } from 'react-icons/fa'
import { ImBooks } from 'react-icons/im'
import { BsQuestionDiamondFill } from 'react-icons/bs'
//Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';
import { useNavigate } from 'react-router-dom';
// APIURL
import { dashboardAPI } from '../../Services/HomeAPIURL';


const Home = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(titleLabelAction({ titleLabel: "HOME" }))
    }, [])

    const authUser = useSelector((state) => state.LoginReducer.list[0]);

    const [data, setData] = useState([])

    const getHomeData = async () => {
        await axios.post(dashboardAPI + `${authUser.institute_id}`)
            .then((res) => {
                setData(res.data)
                //console.log(res.data)

            })
            .catch((err) => {
                console.log("Error : " + err)
            })
    }

    useEffect(() => {
        if (authUser.institute_id) {
            getHomeData();
        }

    }, [authUser])

    return (
        <>
            <div className="row p-2">
                <div className="col-sm-4 cardWrapper">
                    <div className="cardBody blackBackground whiteFont">
                        <span className="cardIcon blackBackground whiteFont"><SiSemanticweb /></span>

                        <span className='cardTitle'>ONLINE OPAC</span>
                        <span className='cardCount'>
                            {(data.length > 0) ? data[0].onlineOpac : 0}
                        </span>

                        <div className='cardFooter' onClick={() => navigate("/n001")}>View More</div>
                    </div>
                </div>
                <div className="col-sm-4 cardWrapper">
                    <div className="cardBody blueBackground whiteFont">
                        <span className="cardIcon blueBackground whiteFont"><MdPictureAsPdf /></span>

                        <span className='cardTitle'>E-BOOKS</span>
                        <span className='cardCount'>
                            {(data.length > 0) ? data[0].pdf : 0}

                        </span>

                        <div className='cardFooter' onClick={() => navigate("/n003")}>View More</div>
                    </div>
                </div>
                <div className="col-sm-4 cardWrapper">
                    <div className="cardBody yellowBackground whiteFont">
                        <span className="cardIcon yellowBackground whiteFont"><BsFillFileSpreadsheetFill /></span>

                        <span className='cardTitle'>QUESTION PAPER</span>
                        <span className='cardCount'>
                            {(data.length > 0) ? data[0].qPaper : 0}

                        </span>

                        <div className='cardFooter' onClick={() => navigate("/n004")}>View More</div>
                    </div>
                </div>
                <div className="col-sm-4 cardWrapper">
                    <div className="cardBody greenBackground whiteFont">
                        <span className="cardIcon greenBackground whiteFont"><FaLink /></span>

                        <span className='cardTitle'>LINKS</span>
                        <span className='cardCount'>
                            {(data.length > 0) ? data[0].link : 0}

                        </span>

                        <div className='cardFooter' onClick={() => navigate("/n007")}>View More</div>
                    </div>
                </div>
                <div className="col-sm-4 cardWrapper">
                    <div className="cardBody levenderBackground whiteFont">
                        <span className="cardIcon levenderBackground whiteFont"><ImBooks /></span>

                        <span className='cardTitle'>SYLLABUS</span>
                        <span className='cardCount'>
                            {(data.length > 0) ? data[0].syllabus : 0}

                        </span>

                        <div className='cardFooter' onClick={() => navigate("/n005")}>View More</div>
                    </div>
                </div>
                <div className="col-sm-4 cardWrapper">
                    <div className="cardBody redBackground whiteFont">
                        <span className="cardIcon redBackground whiteFont"><BsQuestionDiamondFill /></span>

                        <span className='cardTitle'>FAQ</span>
                        <span className='cardCount'>
                            {(data.length > 0) ? data[0].faq : 0}

                        </span>

                        <div className='cardFooter' onClick={() => navigate("/n006")}>View More</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home