const initialData = {
    list: [{titleLabel: "Title"}]
}

const TitleLabelReducer = (state = initialData, action) => {
    switch (action.type) {
        case "ADD_TITLE":
            // console.log("action.payload"+action.payload.loginId)
            const { titleLabel } = action.payload;
            return {
                ...state,
                // list: [...state.list, { loginId: loginId, password: password }]
                list: [{ titleLabel: titleLabel }]

            }

        default: return state
    }
}

export default TitleLabelReducer;