import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import logo from '../../Assets/images/logo.png';
import playstoreIcon from '../../Assets/images/playstoreIcon.png'
import axios from 'axios';
import { deleteMemberShipAPI } from '../../Services/CanclSubAPIURL';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userLogout } from '../../ReduxStore/Action/LoginAction';
import { Col, Row } from 'react-bootstrap';



const CancleMember = (props) => {
    const [isAccepted, setIsAccepted] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [userID, setuserID] = useState();
    const [password, setpassword] = useState();
    const[isShow,setisShow]=useState(false);

    const GotoMainPage = () => {
        navigate('/')
    }

    const handaledelete = (e) => {
       setisShow(true)
    };

   const deletemyaccount =()=>{
    

        axios.post(`${deleteMemberShipAPI}${userID}/${password}`)
      
        .then((res) => {
            console.log("========res.data=======",res);
            if (res.data === "ERROR") {
                toast.error(`Error Occurred While Deleting Your Account`);
            } else if(res.data == "DELETED") {
                toast.success(`Your Account Deleted Successfully`);
                sessionStorage.removeItem("digital-library");
                dispatch(userLogout());
                navigate("/");
            }
        })
        .catch((error) => {
            console.error("Error occurred:", error);
            toast.error("An error occurred. Please try again.");
        });
   }

   const closeModal = () => {
    setisShow(false);
  };
   
    return (
        <>
       
       <Modal
       {...props}
      show={isShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Confirmation to delete account</h4>
        <p>
         Are you sure to delete your account ?
        </p>
       <Row>
       <div className='col-6'></div>
       <div className='col-3'>
       <Button style={{backgroundColor:"red",border:"none"}} onClick={()=>{
                deletemyaccount()
            }}>Delete</Button>
       </div>
           <div className='col-3'>
           <Button style={{border:"none",marginLeft:"10px"}} onClick={closeModal}>Cancel</Button>
           </div>
       </Row>
      </Modal.Body>
      <Modal.Footer>
      
        
      </Modal.Footer>
       </Modal>

           <Row>
            <Col md={3}></Col>
            <Col md={6}>
            <div className="container" style={{backgroundColor:"white",borderRadius:"10px",padding:"20px",marginTop:"20%"}}>
               
               <div className="col-12 text-center mt-4">
               <h1 className='headTitle' style={{ color: "black" }}>Cancel Your Membership</h1>
               <h6  style={{ color: "red", marginTop: "30px" }}>
                   This Process Deletes Your Account Permanently on JCJ Digital-Library.in.
                   After Deleting, Your Personal Data Will Be Removed From Our Site
               </h6>
               <form>
               <label>
               <span style={{ fontSize: "20px", color: "black",paddingRight:"20px" }}>User Id</span>
                   <input
                       type='text' placeholder='enter email'
                       style={{ marginTop: "15px", fontSize: "16px", color: "black",marginLeft:"22px",padding:"3px 10px" }}
                    
                       onChange={(e)=>setuserID(e.target.value)}
                       required
                   />
                  
               </label>
              
               <div className="card-container mt-4">
               <label>
               <span style={{fontSize: "20px", color: "black",paddingRight:"20px" }}>Password</span>
                   <input
                       type='password' placeholder='enter password'
                       style={{ marginTop: "15px", fontSize: "16px", color: "black",padding:"3px 10px" }}
                      
                       onChange={(e)=>setpassword(e.target.value)}
                       required
                   />
                   
               </label>
               </div>
               <div className="card-container mt-4">
               <span
                   className='loginBtn'
                   style={{
                     width: "150px",
                     backgroundColor: "gray",
                     color: "white",
                     marginLeft: "10px",
                     pointerEvents: "initial",
                     opacity: 1
                   }}
                   onClick={GotoMainPage}
                 >
                   Cancel
                 </span>
   
                   <span
                       className='loginBtn'
                       style={{
                           width: "150px",
                           backgroundColor: "red",
                           color: "white",
                           pointerEvents:  "initial" ,
                           opacity: 1 
                       }}
                       onClick={()=>handaledelete()}
                   >
                       Delete Account
                   </span>
   
                  
               </div>
               </form>
           </div>
              </div>
            </Col>
            <Col md={3}></Col>
           </Row>


                

            <div className="footer">

                <div className="col-6 fc1">
                    © JCJ Digital Library 2020 | Managed by: <a href="https://technoaarv.com/" target="_blank">TechnoAarv Solution</a>
                </div>
                <div className="col-6 fc2">
                    <a href="https://play.google.com/store/apps/details?id=com.jcj.digitallibrarysystem&amp;hl=en" target="_blank">
                        <img src={playstoreIcon} alt="Playstore Icon" />
                    </a>
                </div>

            </div>


        </>
    )
}

export default CancleMember
