import React, { useState, useEffect } from 'react';
import axios from "axios";
import Table from 'react-bootstrap/Table';
import { ToastContainer, toast } from 'react-toastify';
import { BsTrashFill } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import TablePagination from '@mui/material/TablePagination';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


//Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { titleLabelAction } from '../../ReduxStore/Action/TitleLabelAction';



//Loader
import Loader from '../CommonComponents/Loader/Loader';

//APIURL
import { saveCategoryMasterAPI, getCategoryMasterAPI, updateCategoryMasterAPI, deleteCategoryMasterAPI } from '../../Services/CategoryAPIURL'
import DeleteModal from '../CommonComponents/DeleteModalComponent/DeleteModal';



const CategoryMaster = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector((state) => state.LoginReducer.list[0]);
    const [loaderOption, setLoaderOption] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        dispatch(titleLabelAction({ titleLabel: "CATEGORY MASTER" }))
    }, [])

    useEffect(() => {
        if (authUser.member_type) {
            if (authUser.member_type !== 1) {
                navigate("/")
            }
        }
    }, [authUser])

    //Data
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([])

    //Form Fields
    const [id, setId] = useState(null);
    const [categoryName, setCategoryName] = useState("");

    //btn css
    const saveBtn = "btn btn-sm btn-primary";
    const updateBtn = "btn btn-sm btn-warning";
    const noneBtn = "d-none"


    //Table
    const [isAscending, setAscending] = useState(false);
    const [sortedBy, setSortedBy] = useState(null);
    const [searchTerm, setSearchTerm] = useState("")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const onSort = (key, isAction) => {
        if (!isAction) {
            setSortedBy(key)
            if (isAscending) setFilteredData([...data.sort((a, b) => a[key] < b[key] ? 1 : -1)])
            else setFilteredData([...data.sort((a, b) => a[key] > b[key] ? 1 : -1)])
            setAscending(!isAscending)
        }
    }

    const onPageChange = (event, nextPage) => {
        setPage(nextPage);
    }
    const onRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setPage(0);
    }

    const TableHeading = [
        { label: 'SR.NO', key: 'id' },
        { label: `Category Name`, key: 'category_name' },
        { label: 'Edit', key: 'edit', value: 'edit', isAction: true },
        { label: 'Delete', key: 'delete', value: 'delete', isAction: true }
    ]

    useEffect(() => {
        setFilteredData(
            data.filter(
                (item) =>
                    item.category_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm]);


    const getData = async () => {
        setLoaderOption(true);
        await axios.post(getCategoryMasterAPI + authUser.institute_id)
            .then((res) => {
                if (res.data === "ERROR") {
                    setLoaderOption(false);
                    toast.error("Error Occured.");
                }
                else {

                    setData(res.data);
                    setFilteredData(res.data);
                    setLoaderOption(false);
                }
            })
            .catch((err) => {
                setLoaderOption(false);
                toast.error("Network error, Try after sometime.")
                console.log("Error : " + err)
            })
    }

    useEffect(() => {
        if (authUser.institute_id) {
            getData();
        }
    }, [authUser])

    const clearFun = () => {
        setId(null)
        setCategoryName("")
    }

    const deleteFun = async () => {
        setShowDeleteModal(false)
        const delObj = {
            "id": id
        }
        await axios.post(deleteCategoryMasterAPI, delObj)
            .then((res) => {
                if (res.data === "FOREIGNKEY") {
                    clearFun();
                    toast.warn("Cant delete, Category is allocated.")
                }
                else if (res.data === "DELETED") {
                    clearFun();
                    getData();
                    toast.success("Category deleted sucessfully.")
                }
                else if (res.data === "ERROR") {
                    clearFun();
                    toast.error("Error occured, try again")
                }
            })
            .catch((err) => {
                clearFun();
                toast.error("Network error, try after sometime.")
                console.log("Error :" + err)
            })
    }

    const updateFun = async () => {

        const updateObj = {
            "id": id,
            "category_name": categoryName
        }
        await axios.post(updateCategoryMasterAPI, updateObj)
            .then((res) => {
                if (res.data === "ERROR") {
                    toast.error("Error occured, Try again later");
                }
                else if (res.data === "UPDATED") {
                    getData();
                    clearFun();
                    toast.success("Category updated sucessfuly");
                }
            })
            .catch((err) => {
                toast.error("Network Error, Try again later");
                console.log("Error :" + err)
            })
    }

    const saveFun = async () => {

        const saveObj = {
            "branch_name": authUser.institute_id,
            "category_name": categoryName
        }

        await axios.post(saveCategoryMasterAPI, saveObj)
            .then((res) => {
                if (res.data === "ERROR") {
                    toast.error("Error occured, Try again later");
                }
                else if (res.data === "SAVED") {
                    getData();
                    clearFun()
                    toast.success("Category saved sucessfuly");
                }
            })
            .catch((err) => {
                toast.error("Network Error, Try again later");
                console.log("Error :" + err)
            })
    }

    const state = { button: 1 };

    const onSubmit = (e) => {
        e.preventDefault();
        if (state.button === 1) {
            saveFun();
        }
        if (state.button === 2) {
            updateFun();
        }
    };

    return (
        <>
            {(loaderOption) ? <Loader /> : null}

            <div className="row">
                <div className="col-sm-6">
                    <Form onSubmit={onSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label><strong>Category Name :</strong></Form.Label>
                            <Form.Control type="text" placeholder="Category Name" required
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                            />
                        </Form.Group>

                        <Button type='submit' onClick={() => (state.button = 1)} className={(id) ? noneBtn : saveBtn}>SAVE</Button>
                        <Button type='submit' onClick={() => (state.button = 2)} className={(id) ? updateBtn : noneBtn}>UPDATE</Button>
                        <Button size='sm' style={{ marginLeft: "8px" }} variant='danger'
                            onClick={() => clearFun()}
                        >CANCLE</Button>
                    </Form>
                </div>
            </div>
            <hr />

            <div className="row">
                <div className="table-responsive mt-2">
                    <Table striped bordered hover size="sm" >
                        <thead className="table-Default">
                            <tr style={{ backgroundColor: "#F5E3E6", backgroundImage: "linear-gradient(200deg, #F5E3E6 10%, #D9E4F5 100%)" }}>
                                {TableHeading.map((item, ind) => {
                                    return (
                                        <th
                                            style={{ fontSize: "12px", fontWeight: "600", borderBottom: "2px solid #D8EDF7" }}
                                            onClick={() => onSort(item.key, item.isAction)}
                                            key={ind}
                                            scope="col"
                                        >
                                            {`${item.label} ${sortedBy === item.key ? isAscending ? '↑' : '↓' : ''}`}
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody style={{ borderBottom: "2px solid #D8EDF7", width: "100%", wordWrap: "break-word" }}>
                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item, index) => {

                                    return (
                                        <tr key={index}>
                                            <td style={{ fontSize: "14px", fontWeight: "500", width: "60px" }}>{item.id}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>{item.category_name}</td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                <Button className='btn-sm p-0 btn-primary' onClick={() => {
                                                    setId(item.id);
                                                    setCategoryName(item.category_name);
                                                }}>
                                                    <FaEdit style={{ margin: "2px" }} />
                                                </Button>
                                            </td>
                                            <td style={{ fontSize: "14px", fontWeight: "500" }}>
                                                <Button className='btn-sm p-0 btn-danger'
                                                    onClick={() => {
                                                        setId(item.id);
                                                        setShowDeleteModal(true);
                                                    }}
                                                >
                                                    <BsTrashFill style={{ margin: "2px" }} />
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                        <tfoot >
                            <tr style={{ padding: "4px" }}>
                                <TablePagination
                                    style={{ padding: 0, borderBottom: "0px" }}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    labelRowsPerPage=" Rows : "
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                    onPageChange={onPageChange}
                                    //classes={{ root: classes.root, input: classes.input, spacer: classes.spacer, selectLabel: classes.selectLabel, toolbar: classes.toolbar, caption: classes.caption, menuItem: classes.menuItem, displayedRows: classes.displayedRows }}
                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                                />
                            </tr>
                        </tfoot>
                    </Table>
                </div>
            </div>
            <DeleteModal deleteFun={deleteFun} setShowDeleteModal={setShowDeleteModal} showDeleteModal={showDeleteModal} />
            <ToastContainer theme='dark' position='top-right' />
        </>
    )
}

export default CategoryMaster